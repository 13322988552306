import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AriaControlLayout, AriaLogin, 
  AriaComplainHistory, AriaOpacitySlider, AriaKLevelSelect,
  AriaSources, AriaDatePicker, AriaTimelineControl,
  getToken, getUser
} from '@ses-air-solutions/react-common'
import '@ses-air-solutions/react-common/dist/index.css'

import bati_geojson from './images/batis.json'

import AriaLogo from './images/logo-aria.webp'
import SuezLogo from './images/suez_logo.png'
import DKGLittoral from './images/DKGdLittoral.png'
import Atmo from './images/Atmo.jpg'
import EURA from './images/Euraenergie.png'
import Port from './images/Port.png'
import ULCO from './images/ULCO.png'

import DKAIRLogo from './images/DKAIR.png'


const logos = [AriaLogo, SuezLogo, DKGLittoral, Port, EURA, Atmo, ULCO]
/*
const ARIAVIEW_SITES = {
  'NOSDO': {
    name: 'NOSDO',
    label: 'NOSDO',
    type: 'airq',
    visu: 'LcS'
  },
  'ARREZO':  {
    name: 'ARREZO',
    label: 'ARREZO',
    type: 'airq',
    visu: 'LcS'
  },
  'IVRY':  {
    name: 'IVRY',
    label: 'IVRY',
    type: 'airq',
    visu: 'LcS'
  }
}*/

const AriaviewStore = {
  title: undefined,
  project: 'ARIAVIEW',
  case: process.env.REACT_APP_CLIENT_DATASET,
  allow_simulation : true,
  external_sources : true,
  chart_type: 'line_simulation',
  dv_name : 'DKAIR',
  leftPanel: false,
  timezone: -parseInt((new Date().getTimezoneOffset())/60),

  // User info
  user: {},

  pol_group: 'PM10',
  //inventory_emissions_window:false,
  window_type:'dashboard',
  reports: {
    monthly : false,
    daily : false
  },

  // Controls
  klevel: 0,
  // klevels: 2,
  map: {
    opacity: 0.7
  },
  controls: {},
  extraction_zones: {},
  chart_types: ['line', 'observations', 'meteo', 'sources_contribution'],
  chart_open: false,
  markers_colors: {},
  // Services
  services: {
    timeseries: {
      receptors: {
        name: 'points/ts/',
        method: 'POST',
        paths: [],
        observationType: ''
      },
      weather_sensors: {
        name: 'ariaview/observations',
        method: 'GET',
        observationType: 'met'
      },
      emission_sensors: {
        name: 'ariaview/observations',
        method: 'GET',
        observationType: 'aq'
      }
    }
  },
  domain:{},
  domain_nesting: {
  },

  // Current palette
  palette: undefined,
  regulations: undefined,

  // Meteo
  weather_sensors: [],
  weather_sensor: {},
  weather_monitoring_sensor: {
    idAirQualityStation: 45095652,
  },
  windcomps : {
    ucomp: 'U',
    vcomp: 'V'
  },
  wind_compass_config: {
    wind_direction: 'DIREC',
    wind_average: 'VMOD',
    temperature: 'TEMP',
    total_rain:'RAINF',
    wind_direction_unit: '°',
    wind_average_unit: 'm/s',
    temperature_unit: '°C',
    total_rain_unit:'mm/h',
    rain_info_tooltip:'Pluie : en mm/h',
    temperature_info_tooltip:'Température : en Degrés Celsius',
    wind_info_tooltip:'Vent : Direction et Vitesse',
    meteo_station_info:'Données Météo France au point (51.025, 2.325)'
  },

  weather_data: undefined,
  
  

  // Emissions
  emission_sensors: {
  },//*/

  emission_sensor_thresholds: {
    'NO2' : 200,
    'PM10' : 80,
    'PM2.5' : 25,
    'SO2' : 350,
    'COVNM' : 2
  },

  emission_charts: {
    pols: ["Temp", "NH3", "NO2","PM10", "PM2.5", "PM 1", "SO2"],
    pUnits: {
      "NH3" : 'µg/m3',
      "Temp" : '°C',
      "NO2" : 'µg/m3',
      "PM10" : 'µg/m3',
      "PM2.5" : 'µg/m3',
      "PM 1" : 'µg/m3',
      "SO2" : 'µg/m3'
    },
  },

  // Emissions
  measure_stations: {
  },//*/

  // Receptor
  receptors: {
  },

  receptor_thresh : {
    '003' : 200,
    '001' : 40,
    '002' : 25,
    '004' : 350,
    '005' : 2
  },


  // Data Vector Layers
  vector_layers: [
  ],
	
  sources_contribution_groups: ['PM10', 'PM2.5', 'NO2', 'SO2', 'COVNM'],
  sources_contribution_average: true,
  private_sources: true,

  // Complains
  complains: [],
  selectedComplaint: {},
  complain: {
    isFormOpened: false,
    lon: undefined,
    lat: undefined,
    content: undefined,
  },

  // Application current state
  // /////////////////////////////
  datasets: [],
  is_atomic: true,
  dataset: {},
  data_type: 'airq',
  variable: {},
  date: undefined, // The date of the modeling computation
  timeframe: undefined,

  timeseries: {
    datedeb: {value: new Date(new Date().getTime() - 24*60*60*1000), max: new Date()},
    datefin: {value: new Date(), max: new Date(), min: new Date(new Date().getTime() - 24*60*60*1000)},
    //datedeb: {value: new Date(1633874400000)},
    //datefin: {value: new Date(1633874400000 + 24*60*60*1000)},
    //datedeb: {value: undefined},
    //datefin: {value: undefined},
  },

  // Inventory sources 
  current_inventory_source:{},
  selected_inventory_sources:[],
  selected_inventory_id:undefined,
  selected_inventory:{},
  UTM_Zone:31,
  domain:{},

  background_pollution:{
    used:true,
    value:0
  },

  // Others
  variableFileMap: {
    'M001S001': 'COV_DECHET',
    'M002S001': 'COV_MACHEFER',//    
    'M003S001': 'COV_All-sources',
    'M004S002': 'H2S_DECHET',
    'M005S002': 'H2S_MACHEFER',
    'M006S002': 'H2S_All-Sources',
    'M007S003': 'NH3_DECHET',
    'M008S003': 'NH3_MACHEFER',
    'M009S003': 'NH3_All-sources',
    'M010S004': 'ODEUR_DECHET',
    'M011S004': 'ODEUR_MACHEFER',
    'M012S004': 'ODEUR_All-Sources',
  },
  client: {
    name: process.env.REACT_APP_CLIENT_NAME
  }
}


// ////////////////////
// Render function
// ////////////////////
const App = () => {
  const { t } = useTranslation()

  // Token handler
  // ---------------
  const [tokenInCookie, setTokenInCookie] = React.useState(getToken())
  const [inventoryEmissionsWindow, setInventoryEmissionsWindow] = React.useState(false)
  const [userInfo, setUserInfo] = React.useState(getUser())
  const leftPanel = AriaviewStore['leftPanel']
  const [windowType, setWindowType] = React.useState("dashboard")
  const tokenHandler = React.useCallback(
    (token, userInfo) => {
      setTokenInCookie(token)
      setUserInfo(userInfo)
    }, []
  )
  if (userInfo == 'DEMO'){
    AriaviewStore.private_sources = false
  }

  // UI 
  // ///////////////////////////////
  return (
    <div className="container" style={{overflow: 'hidden'}}>
      {(tokenInCookie && tokenInCookie.length>0 && userInfo) ?
        <AriaControlLayout
          store={AriaviewStore}
          title={DKAIRLogo}
          titleimage = {true}
          logos={logos}
          selectedDataset={`ARIAVIEW_${AriaviewStore['case']}_${userInfo}_RESULT_LcS`}
          project={'ARIAVIEW'}
          dv_name = {`DKAIR_${userInfo}`}
          modelType={AriaviewStore['case']}
          userinfo={
            userInfo}
          setWindowType={(e) => {setWindowType(e)}}
          onClickOnExit={() => {setTokenInCookie(undefined);window.location.reload(false)}}

          controlPanelProps={{
            //toolbarButtons: ['Info', 'Report', 'Help','Monitoring','DashBoard']
            toolbarButtons: ['Monitoring','DashBoard']
          }}
          mapProps = {{
            withTimeseries: true,
            withComplains: false,
            ModelWeatherCheck: false,
            userDataLayer: {polygon: bati_geojson, name: "Bâtiments Industriels ", checked: true},
            
          }}
          infoPanelProps = {{
            datetimeFormat: '{y}-{m}-{d} {h}h',
            withSourcesContribution: true,
            isVariableReadFromAPI: false
          }}
        >
          
        { windowType === 'monitoring' && 
          <AriaSources
            title={t('ControlPanel.Select_dataset')}
            project={'ARIAVIEW'}
            selectedDataset={`ARIAVIEW_${AriaviewStore['case']}_${userInfo}_RESULT_LcS`}
            dv_name = {`DKAIR_${userInfo}`}
            hidden
            externalSourcesButton={true}
            backgroundPollutionButton={true}
            in_toolbar={true}
          />
        }

          {leftPanel && (<AriaDatePicker
            title={t('ControlPanel.Select_a_date')}
          /> )}

          {leftPanel && ( <AriaTimelineControl
            title={t('ControlPanel.Select_a_timeframe')}
            isUTC={false}
            datetimeFormat='{h}:{i}'
          /> )}

          {/* <AriaComplainHistory
            title={t('ControlPanel.Complain_history')}
          /> */}

          {/* <AriaKLevelSelect
            title={t('ControlPanel.K_Level')}
          /> */}
          {leftPanel && ( <AriaOpacitySlider
            title={t('ControlPanel.Opacity')}
          /> )}
        </AriaControlLayout>
        :
        <AriaLogin 
          tokenHandler={tokenHandler} 
        />
      }
    </div>
  )
}

export default App
