import './index.css'

import React from 'react'
import ReactDOM from 'react-dom'
import './i18n'
import App from './App'

import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import { AriaTheme } from '@ses-air-solutions/react-common'

ReactDOM.render(
  <ThemeProvider theme={AriaTheme}>
    <CssBaseline />
    <App />
  </ThemeProvider>
  ,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
